<template>
  <v-flex>
    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
      offset-y max-width="290px" min-width="290px">
      <template v-slot:activator="{ on }">
        <v-text-field v-model="dateFormatted" :label="label" :hint="texto" persistent-hint
          @blur="date = parseDate(dateFormatted)" v-on="on" readonly prepend-inner-icon="mdi-calendar"
          @click:prepend-inner="[(menu1 = true)]" class="title"></v-text-field>
      </template>
      <v-date-picker type="month" v-model="date" locale="pt-br"
        @input="[(menu1 = false), $emit('dateValue', date)]" format="MM/yyyy"></v-date-picker>
    </v-menu>
  </v-flex>
</template>

<script>
export default {
  name: "CalendarMonth",
  props: ["label", "texto"],
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      current_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateFormatted: "",
      menu1: false,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  created() {
    this.dateFormatted = this.formatDate(this.date);
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-01`;
    },
  },
};
</script>
